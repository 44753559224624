import React from "react"
import { graphql } from "gatsby"
import BlogBody from "../components/blog-body"

class Blog extends React.Component {
  render() {
    const posts = this.props.data.allMdx.edges

    const featuredPosts = posts.filter(({ node }) => {
      return node.frontmatter.featured
    })

    const regularPosts = posts.filter(({ node }) => {
      return !node.frontmatter.featured
    })

    return (
      <BlogBody
        {...this.props}
        regularPosts={regularPosts}
        featuredPosts={featuredPosts}
      />
    )
  }
}

export default Blog

export const pageQuery = graphql`
  query($category: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featured
            category
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
